import React from "react";
import { Link } from "react-router-dom";
import { signOut } from "../api/firebase";
import { ReactComponent as Logo } from "../images/CortisolLogo.svg";

function Navbar(props) {
    function getSignOut() {
        if (props.signOut)
            return (
                <>
                    <button className='rounded bold yellow button leftSpace' style={{ justifySelf: "end" }} onClick={signOut}>
                        Sign out
                    </button>
                </>
            );
        return null;
    }

    return (
        <div className='gray' style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 10 }}>
            <div style={{ height: "100%", flex: 1, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                <Link to='/home'>
                    <Logo style={{ height: 30, width: 60 }}></Logo>
                </Link>
                <Link to='/home' style={{ textDecoration: "none" }}>
                    <h1 className='bold title leftSpace'>{props.title}</h1>
                </Link>
            </div>
            {/* <div style={{ height: "100%", flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}></div> */}
            <div style={{ height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                {props.children}
                {getSignOut()}
            </div>
        </div>
    );
}

export default Navbar;
