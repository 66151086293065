import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./Heatmap.css";

import _ from "lodash";

function StessHeatMap(props) {
    const [heatmapData, setHeatmapData] = useState([]);
    const [heatmapMax, setHeatmapMax] = useState([]);
    const [studentCount, setStudentCount] = useState(1);

    useEffect(
        function () {
            let tempHeatmapData = new Map();
            let allAssignments = [];
            if (props.studentData === undefined) return;
            props.studentData.forEach(function (sD) {
                if (sD.assignments === undefined) return;
                sD.assignments.forEach(function (assignment) {
                    let mapKey = assignment.date.toDate().toString();
                    if (tempHeatmapData.get(mapKey) === undefined) {
                        tempHeatmapData.set(mapKey, []);
                    }
                    tempHeatmapData.get(mapKey).push(assignment);
                    allAssignments.push(assignment);
                });
            });

            let upcomingMapData = [];
            let tempHeatmapMax = 0;
            let currentDate = midnightDate(new Date());
            for (let i = 0; i < 42; i++) {
                let assignmentCount = 0;
                let assignmentArray = tempHeatmapData.get(currentDate.toString());

                if (assignmentArray === undefined) {
                    assignmentCount = 0;
                    assignmentArray = [];
                } else assignmentCount = assignmentArray.length;

                if (assignmentCount > tempHeatmapMax) tempHeatmapMax = assignmentCount;

                upcomingMapData.push({
                    date: currentDate.toString(),
                    count: assignmentCount,
                    assignments: assignmentArray,
                });

                currentDate.setDate(currentDate.getDate() + 1);
            }

            setHeatmapData(upcomingMapData);
            setHeatmapMax(tempHeatmapMax + 1);
            setStudentCount(props.studentData.length);
        },
        [props.studentData]
    );

    return (
        <div
            className='rounded gray thickPadding'
            style={{
                display: "grid",
                gridTemplateColumns: "repeat(14, 1fr)",
                gridTemplateRows: "repeat(3, 1fr)",
                gap: "var(--thickMargin)",
                // height: "25em",
                // aspectRatio: "7/5",
            }}>
            {heatmapData.map(function (data, index) {
                return <HeatmapSquare data={data} studentCount={studentCount} max={heatmapMax} index={index} key={index}></HeatmapSquare>;
            })}
        </div>
    );
}

function sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}

function HeatmapSquare(props) {
    const [dayDate, setDayDate] = useState("");
    const [assignments, setAssignments] = useState([]);
    const [averageAssignments, setAverageAssignments] = useState(0);

    useEffect(
        function () {
            let newSet = _.uniqWith(props.data.assignments, _.isEqual); // new Set(assignmentStrings);
            setAssignments([...newSet]);

            let date = new Date(props.data.date);
            setDayDate(`${date.getMonth() + 1}/${date.getDate()}`);

            setAverageAssignments(Math.round((props.data.count / props.studentCount) * 100) / 100);
        },
        [props.data, props.studentCount]
    );

    function SquareContent() {
        return (
            <div
                key={props.index}
                style={{
                    aspectRatio: "1/1",
                    display: "flex",
                    backgroundColor: "white",
                    overflow: "hidden",
                    position: "relative",
                    border: sameDay(new Date(), new Date(props.data.date)) ? "0.2rem solid var(--primary)" : "none",
                }}
                className='rounded hoverShadow'>
                <div
                    style={{
                        backgroundColor: "var(--primary)",
                        opacity: props.data.count / props.max,
                        zIndex: 10,
                        flex: 1,
                        width: "100%",
                    }}></div>
                <div
                    style={{
                        // backgroundColor: "var(--primary)",
                        zIndex: 20,
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        display: "flex",
                        // justifyContent: "center",
                        // alignItems: "center",
                    }}>
                    <h1 className='bold header thinPadding' style={{ color: "var(--gray)" }}>
                        {dayDate}
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <Popup
            trigger={(open) => (
                <button className='popup-button'>
                    <SquareContent></SquareContent>
                </button>
            )}
            position='bottom center'
            closeOnDocumentClick>
            <h1 className='bold header thinBottomMargin' style={{ color: "var(--gray)" }}>
                {dayDate}
            </h1>
            <h1 className='' style={{ color: "var(--gray)" }}>
                Your students have an average of {averageAssignments} assignment{averageAssignments > 1 ? "s" : ""} each this day.
            </h1>
            {props.data.assignments.length > 0 && (
                <>
                    <h1 className='bold thinTopMargin'>Assignments:</h1>
                    <ul>
                        {assignments.map(function (assignment) {
                            return (
                                <h1 className='rounded gray thinPadding thinTopMargin' style={{ color: "var(--gray)" }}>
                                    {assignment.name}
                                </h1>
                            );
                        })}
                    </ul>
                </>
            )}
        </Popup>
    );
}

function midnightDate(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0);
}

// function SquareDayPopup(props) {
//     return (
//         <Popup trigger={(open) => <button className='button'>Trigger - {open ? "Opened" : "Closed"}</button>} position='right center' closeOnDocumentClick>
//             <span> Popup content </span>
//         </Popup>
//     );
// }

export default StessHeatMap;
