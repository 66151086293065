import React from "react";
import Loader from "react-loader-spinner";

function LoadingOverlay(props) {
    return (
        <div
            style={{
                width: "100vw",
                height: "100vh",
                backgroundColor: "var(--white)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Loader type="ThreeDots" color="var(--primary)"></Loader>
            {props.children}
        </div>
    );
}

export default LoadingOverlay;
