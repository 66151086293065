import { useDocument } from "@nandorojo/swr-firestore";
import React, { useEffect } from "react";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router";
import { getDomain, getUID, refreshToken } from "../api/firebase";
import { ReactComponent as Logo } from "../images/CortisolLogo.svg";

function Welcome(props) {
    const navigation = useHistory();
    const { data: userData } = useDocument(`schools/${getDomain()}/teachers/${getUID()}`, { listen: true });

    useEffect(
        function () {
            async function refresh() {
                if (userData !== undefined && userData.exists) {
                    await refreshToken();
                    navigation.push("/home");
                }
            }
            refresh();
        },
        [userData, navigation]
    );

    return (
        <div
            className='thickPadding'
            style={{
                width: "100vw",
                height: "100vh",
                backgroundColor: "var(--white)",
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
            }}>
            <Logo style={{ height: 30, width: 60 }}></Logo>
            <h1 className='bold title thickTopMargin'>Welcome</h1>
            <h1 className='header thickTopMargin'>Please wait while we verify your organization.</h1>
            <Loader type='ThreeDots' color='var(--primary)'></Loader>
        </div>
    );
}

export default Welcome;
