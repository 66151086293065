import React from "react";
import { Link } from "react-router-dom";

function Footer(props) {
    return (
        <div
            style={{
                display: "block",
                width: "100%",
                backgroundColor: "var(--darkGray)",
                boxSizing: "border-box",
                padding: "1vw 5vw",
                display: "flex",
                flexDirection: "row",
            }}>
            <h1 className='bold header' style={{ color: "white" }}>
                Cortisol App
            </h1>
            <Link to='/privacy'>
                <h1 className='header' style={{ color: "white", marginLeft: "5rem" }}>
                    Privacy
                </h1>
            </Link>
        </div>
    );
}

export default Footer;
