import React, { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";

function AssignmentCard(props) {
    const [assignmentDate, setAssignmentDate] = useState("");

    useEffect(
        function () {
            let date = props.data.date.toDate();
            setAssignmentDate(`${date.getMonth() + 1}/${date.getDate()}`);
        },
        [props.data]
    );

    return (
        <div
            style={{
                width: "10em",
                marginRight: "var(--thickMargin)",
                borderTop: "0.5em solid var(--primary)",
                flex: "none",
                display: "flex",
                flexDirection: "column",
            }}
            className='rounded gray thickPadding hoverShadow'>
            <div style={{ flex: 1 }}>
                <h1 className='bold header thinBottomMargin'>{props.data.name}</h1>
                <h1 className='bold text thinBottomMargin' style={{ color: "var(--gray)" }}>
                    {assignmentDate}
                </h1>
                <h1 className='text' style={{ color: "var(--gray)" }}>
                    {props.data.description}
                </h1>
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }} className='thickTopMargin'>
                <button
                    style={{ padding: 0, margin: 0, border: "none", outline: "none", cursor: "pointer" }}
                    onClick={() => props.editAssignment(props.data.id)}>
                    <Icon.PencilFill color='var(--darkGray)' className='centeredIcon leftSpace' size={20} />
                </button>
            </div>
        </div>
    );
}

export default AssignmentCard;
