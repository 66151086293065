import { useCollection, useDocument } from "@nandorojo/swr-firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { analytics, getDomain } from "../api/firebase";
import LoadingOverlay from "../components/LoadingOverlay";
import Navbar from "../components/Navbar";
import StressHeatMap from "../components/Heatmap/StessHeatMap";

import "../components/DatePickerStyles/DatePicker.css";
import ScrollContainer from "react-indiana-drag-scroll";
import ReactModal from "react-modal";
import Footer from "../components/Footer";
import AssignmentCard from "../components/AssignmentCard";
import ClassSettings from "../components/ClassSettings";
import CreateAssignmentContent from "../components/modals/CreateAssignmentContent";
import EditAssignmentContent from "../components/modals/EditAssignmentContent";

function Class(props) {
    const [createAssignmentModal, setCreateAssignmentModal] = useState(false);
    const [editAssignmentModal, setEditAssignmentModal] = useState(false);
    const [modalAssignmentID, setModalAssignmentID] = useState("");
    const [modalAssignment, setModalAssignment] = useState({});

    const { classID } = useParams();

    const { data: studentData } = useCollection(`schools/${getDomain()}/students`, {
        where: ["classes", "array-contains", classID],
        limit: 100,
        listen: true,
    });
    const { data: classData } = useDocument(`schools/${getDomain()}/classes/${classID}`, { listen: true });
    const { data: assignmentData } = useCollection(`schools/${getDomain()}/assignments`, { listen: true, where: ["class", "==", classID] });

    function closeModal() {
        setCreateAssignmentModal(false);
        setEditAssignmentModal(false);
    }

    useEffect(function () {
        analytics.logEvent("page_view");
    }, []);

    if (!classData || !studentData || !assignmentData) return <LoadingOverlay></LoadingOverlay>;

    return (
        <div>
            <Navbar title={classData.name} signOut>
                <button className='rounded bold white button leftSpace' onClick={() => setCreateAssignmentModal(true)}>
                    + Assignment
                </button>
            </Navbar>
            <div style={{ width: "90vw", marginLeft: "5vw" }}>
                {/* STRESS SECTION */}

                <h1 className='bold title thickBottomMargin' style={{ marginTop: "3vw" }}>
                    Student Assignments:
                </h1>
                <StressHeatMap studentData={studentData}></StressHeatMap>

                {/* ASSIGNMENTS SECTION */}
                {assignmentData.length > 0 && (
                    <>
                        <h1 className='bold title thickBottomMargin' style={{ marginTop: "3vw" }}>
                            Class Assignments:
                        </h1>
                        <ScrollContainer className='noScrollBar assignmentsScrollContainer'>
                            {assignmentData.map(function (data) {
                                return (
                                    <AssignmentCard
                                        data={data}
                                        editAssignment={function (assignmentID) {
                                            setModalAssignment(assignmentData.find((assignment) => assignment.id === assignmentID));
                                            setEditAssignmentModal(true);
                                            setModalAssignmentID(assignmentID);
                                        }}></AssignmentCard>
                                );
                            })}
                        </ScrollContainer>
                    </>
                )}
            </div>

            {/* OPTIONS SECTION */}

            <ClassSettings classID={classID} classData={classData}></ClassSettings>

            <Footer></Footer>

            <ReactModal closeTimeoutMS={500} isOpen={createAssignmentModal} onRequestClose={closeModal}>
                <CreateAssignmentContent
                    open={createAssignmentModal}
                    closeFunction={closeModal}
                    classID={classID}
                    classData={classData}></CreateAssignmentContent>
            </ReactModal>
            <ReactModal closeTimeoutMS={500} isOpen={editAssignmentModal} onRequestClose={closeModal}>
                <EditAssignmentContent
                    assignmentID={modalAssignmentID}
                    assignment={modalAssignment}
                    open={editAssignmentModal}
                    closeFunction={closeModal}
                    classID={classID}
                    classData={classData}></EditAssignmentContent>
            </ReactModal>
        </div>
    );
}

export default Class;
