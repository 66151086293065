const firebaseConfig = {
    apiKey: "AIzaSyAK8lwx_9KbbJ1w8nsNKuorcDDE8RgqfEk",
    authDomain: "student-stress-7a5c0.firebaseapp.com",
    projectId: "student-stress-7a5c0",
    storageBucket: "student-stress-7a5c0.appspot.com",
    messagingSenderId: "796328214057",
    appId: "1:796328214057:web:5fcde7323aa7900ca6a638",
    measurementId: "G-H0J9B0CVZR",
};
export default firebaseConfig;
