import { useDocument } from "@nandorojo/swr-firestore";
import React from "react";
import Loader from "react-loader-spinner";
import { getDomain } from "../api/firebase";

// import { BsPersonFill } from "react-icons/bs";

import * as Icon from "react-bootstrap-icons";
import { Link } from "react-router-dom";

function ClassCard(props) {
    const { data: classData } = useDocument(`schools/${getDomain()}/classes/${props.classID}`, { listen: true });

    if (!classData)
        return (
            <div style={{ aspectRatio: "1/1", justifyContent: "center", alignItems: "center", display: "flex" }} className='rounded gray padding10'>
                <Loader color='var(--primary)' type='ThreeDots'></Loader>
            </div>
        );

    function getStudentCount() {
        try {
            return (
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "var(--thickMargin)" }}>
                    {classData.studentEmails.length}
                    <Icon.PersonFill color='var(--darkGray)' className='centeredIcon leftSpace' size={20} />
                </div>
            );
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    return (
        <Link to={`/class/${classData.id}`} style={{ textDecoration: "none" }}>
            <div className='rounded gray thickPadding hoverShadow' style={{ borderTop: "0.5em solid var(--primary)" }}>
                <div
                    //  style={{ aspectRatio: "1/1" }}
                    className=''>
                    <h1 className='bold header thickBottomMargin'>{classData.name}</h1>
                    <h1 className='header'>{classData.description}</h1>

                    {getStudentCount()}
                    {/* {classData.studentEmails.join(", ")} */}
                </div>
            </div>
        </Link>
    );
}

export default ClassCard;
