import "./App.css";

import { BrowserRouter as Router, Switch, Route, useHistory, useLocation } from "react-router-dom";
import About from "./pages/About";
import SignIn from "./pages/SignIn";
import { useEffect } from "react";

import firebase from "firebase/app";
import Home from "./pages/Home";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { FuegoProvider } from "@nandorojo/swr-firestore";
import firebaseConfig from "./api/firebaseConfig";
import { Fuego } from "./api/firebase";
import Class from "./pages/Class";
import Welcome from "./pages/Welcome";
import UserLoader from "./components/UserLoader";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const fuego = new Fuego(firebaseConfig);

function App() {
    return (
        <FuegoProvider fuego={fuego}>
            <Router>
                <AppLogic></AppLogic>
                <Switch>
                    <Route path='/about'>
                        <About></About>
                    </Route>
                    <Route path='/signin'>
                        <SignIn></SignIn>
                    </Route>
                    <Route path='/welcome'>
                        <UserLoader>
                            <Welcome></Welcome>
                        </UserLoader>
                    </Route>
                    <Route path='/home'>
                        <UserLoader>
                            <Home></Home>
                        </UserLoader>
                    </Route>
                    <Route path='/class/:classID'>
                        <UserLoader>
                            <Class></Class>
                        </UserLoader>
                    </Route>
                    <Route path='/privacy'>
                        <PrivacyPolicy></PrivacyPolicy>
                    </Route>
                    <Route path='/'>
                        <UserLoader>
                            <Home></Home>
                        </UserLoader>
                    </Route>
                </Switch>
            </Router>
        </FuegoProvider>
    );
}

function AppLogic(props) {
    const navigation = useHistory();
    const location = useLocation();

    useEffect(
        function () {
            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    // console.log(user);
                } else {
                    console.log("no user");
                    const noSignInRedirectPages = ["/signin", "/privacy"];
                    if (!noSignInRedirectPages.includes(location.pathname)) {
                        navigation.push("/signin");
                    }
                }
            });
        },
        [navigation, location]
    );

    return <div style={{ height: 0, width: 0 }}></div>;
}

export default App;
