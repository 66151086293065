import React, { useState } from "react";
import { getDomain, getUID } from "../api/firebase";
import Modal from "react-modal";
import Navbar from "../components/Navbar";

import "react-multi-email/style.css";
import ClassCard from "../components/ClassCard";
import { useDocument } from "@nandorojo/swr-firestore";
import LoadingOverlay from "../components/LoadingOverlay";
import CreateClassContent from "../components/modals/CreateClassContent";

import NoClasses from "../images/NoClassesImage.png"; // Tell webpack this JS file uses this image
import Footer from "../components/Footer";

function Home(props) {
    const [createClassModal, setCreateClassModal] = useState(false);

    function closeModal() {
        setCreateClassModal(false);
    }

    const { data: teacherData, error } = useDocument(`schools/${getDomain()}/teachers/${getUID()}`, { listen: true });

    Modal.setAppElement("body");

    function getClassCards() {
        if (teacherData.classes !== undefined) return teacherData.classes.map((classID) => <ClassCard key={classID} classID={classID}></ClassCard>);
        return null;
    }
    if (error) return <p>Error!</p>;

    if (!teacherData) return <LoadingOverlay></LoadingOverlay>;

    if (teacherData.classes === undefined || teacherData.classes.length < 1) {
        return (
            <div>
                <Navbar signOut>
                    <button className='rounded bold white button leftSpace' onClick={() => setCreateClassModal(true)}>
                        New Class
                    </button>
                </Navbar>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                    className='thickPadding'>
                    <img src={NoClasses} style={{ width: "30em", height: "auto" }} alt='No classes.'></img>
                    <h1 className='title'>Get started by creating a class.</h1>
                </div>
                <Modal closeTimeoutMS={500} isOpen={createClassModal} onRequestClose={closeModal}>
                    <CreateClassContent open={createClassModal} closeFunction={closeModal}></CreateClassContent>
                </Modal>
            </div>
        );
    }

    return (
        <div>
            <Navbar signOut>
                <button className='rounded bold white button leftSpace' onClick={() => setCreateClassModal(true)}>
                    New Class
                </button>
            </Navbar>
            <div
                style={{
                    display: "grid",
                    gap: "var(--thickMargin)",
                    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr)) ",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                }}
                className='thickPadding'>
                {getClassCards()}
            </div>
            <Modal closeTimeoutMS={500} isOpen={createClassModal} onRequestClose={closeModal}>
                <CreateClassContent open={createClassModal} closeFunction={closeModal}></CreateClassContent>
            </Modal>
        </div>
    );
}

export default Home;
