import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import { useHistory } from "react-router-dom";
import { deleteClass, editClass } from "../api/firebase";

function ClassSettings(props) {
    const navigation = useHistory();

    const [studentAdditionEmails, setStudentAdditionEmails] = useState([]);
    const [currentStudentEmails, setCurrentStudentEmails] = useState([]);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const [buttonLoading, setButtonLoading] = useState(false);
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);

    useEffect(
        function () {
            setCurrentStudentEmails(props.classData.studentEmails);
            setName(props.classData.name);
            setDescription(props.classData.description);
            setCurrentStudentEmails(props.classData.studentEmails);
            setCurrentStudentEmails(props.classData.studentEmails);
        },
        [props.classData]
    );

    function removeStudentEmail(studentEmail) {
        setCurrentStudentEmails(currentStudentEmails.filter((email) => email !== studentEmail));
        setUnsavedChanges(true);
    }

    function addNewStudents() {
        let newEmails = studentAdditionEmails.filter((item) => currentStudentEmails.indexOf(item) < 0);
        setCurrentStudentEmails([...currentStudentEmails, ...newEmails]);
        setStudentAdditionEmails([]);
        setUnsavedChanges(true);
    }

    return (
        <div
            style={{
                width: "100%",
                boxSizing: "border-box",
                paddingLeft: "5vw",
                paddingRight: "5vw",
                paddingTop: "3vw",
                paddingBottom: "3vw",
                borderTopRightRadius: "var(--borderRadius)",
                borderTopLeftRadius: "var(--borderRadius)",
                marginTop: "3vw",
            }}
            className='gray'>
            <h1 className='bold title thickBottomMargin'>Class Options:</h1>
            {/* <h1 className='bold header thickBottomMargin'>Add students:</h1> */}
            <h1 className='bold header thickBottomMargin'>Name:</h1>
            <input
                className='rounded white input thickBottomMargin wide'
                placeholder='Name...'
                value={name}
                onChange={(event) => {
                    setName(event.target.value);
                    setUnsavedChanges(true);
                }}></input>
            <h1 className='bold header thickBottomMargin'>Description:</h1>
            <textarea
                className='rounded white textarea thickBottomMargin wide'
                placeholder='Description...'
                value={description}
                onChange={(event) => {
                    setDescription(event.target.value);
                    setUnsavedChanges(true);
                }}></textarea>
            <h1 className='bold header thickBottomMargin'>Add students:</h1>
            <ReactMultiEmail
                style={{
                    borderRadius: "var(--borderRadius)",
                    border: "none",
                    fontFamily: "Open Sans",
                }}
                placeholder='Student emails ... '
                emails={studentAdditionEmails}
                onChange={(emails) => {
                    setStudentAdditionEmails(emails);
                }}
                validateEmail={(email) => {
                    return isEmail(email); // return boolean
                }}
                getLabel={(email, index, removeEmail) => {
                    return (
                        <div
                            style={{
                                backgroundColor: "var(--lightGray)",
                                width: "auto",
                                display: "inline",
                                fontFamily: "'Open Sans'",
                            }}
                            data-tag
                            key={index}>
                            {email}
                            <span data-tag-handle onClick={() => removeEmail(index)}>
                                ×
                            </span>
                        </div>
                    );
                }}
            />

            <button className='bold rounded yellow button thickTopMargin' disabled={studentAdditionEmails.length === 0} onClick={addNewStudents}>
                Add Students
            </button>

            {currentStudentEmails.length > 0 && (
                <>
                    <h1 className='bold header thickBottomMargin thickTopMargin'>Manage students:</h1>
                    <div style={{ width: "100%", height: "100%" }}>
                        {currentStudentEmails.map((email, index) => {
                            return <EmailTag email={email} key={index} removeEmail={removeStudentEmail}></EmailTag>;
                        })}
                    </div>
                </>
            )}
            <button
                className='bold rounded yellow button thickTopMargin flexCenter loaderButton'
                disabled={!unsavedChanges || currentStudentEmails.length === 0 || buttonLoading}
                onClick={async () => {
                    setButtonLoading(true);
                    await editClass(props.classID, name, description, currentStudentEmails);
                    setButtonLoading(false);
                }}>
                {buttonLoading ? <Loader type='ThreeDots' color='var(--white)' height={30}></Loader> : "Save Changes"}
            </button>

            <button
                className='bold rounded red button thickTopMargin flexCenter loaderButton'
                style={{ marginTop: "3rem" }}
                disabled={deleteButtonLoading}
                onClick={async () => {
                    setDeleteButtonLoading(true);
                    await deleteClass(props.classID, navigation);
                    // navigation.push("/home");
                }}>
                {deleteButtonLoading ? <Loader type='ThreeDots' color='var(--white)' height={30}></Loader> : "Delete Class"}
            </button>
        </div>
    );
}

function EmailTag(props) {
    return (
        <div
            style={{
                backgroundColor: "var(--white)",
                width: "auto",
                display: "inline-block",
                fontFamily: "'Open Sans'",
                padding: "var(--padding)",
                borderRadius: "var(--borderRadius)",
                marginRight: "var(--margin)",
                marginBottom: "var(--margin)",
            }}>
            {props.email}
            <span style={{ padding: "var(--margin)", cursor: "pointer" }} onClick={() => props.removeEmail(props.email)}>
                ×
            </span>
        </div>
    );
}

export default ClassSettings;
