import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import LoadingOverlay from "./LoadingOverlay";

function UserLoader(props) {
    const [loaded, setLoaded] = useState(false);

    useEffect(
        function () {
            function checkForUser() {
                if (loaded) return;
                if (firebase.auth().currentUser) {
                    setLoaded(true);
                }
            }

            let checkInterval = setInterval(checkForUser, 50);
            checkForUser();

            return function () {
                clearInterval(checkInterval);
            };
        },
        [loaded]
    );

    if (!loaded) return <LoadingOverlay></LoadingOverlay>;
    else return <div>{props.children}</div>;
}

export default UserLoader;
