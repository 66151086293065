import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { signIn } from "../api/firebase";
import { ReactComponent as Logo } from "../images/CortisolLogo.svg";
import GoogleIcon from "../images/g-logo.png";

function SignIn(props) {
    // const [domain, setDomain] = useState("");

    const navigation = useHistory();

    return (
        <div style={{ backgroundColor: "var(--white)", width: "100vw", height: "100vh", display: "flex", flexDirection: "row" }}>
            <div
                style={{
                    backgroundColor: "var(--white)",
                    height: "100vh",
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                <Logo style={{ width: "20vw", height: "20vw" }}></Logo>
                <button className='button googleButton' onClick={() => signIn(navigation)} style={{ marginTop: "5vw" }}>
                    <img src={GoogleIcon} className='googleIcon' alt='Google Icon'></img>
                    Sign In With Google
                </button>
                <h1 className='thinTopMargin'>
                    By signing in, you agree to our <Link to='/privacy'>privacy policy.</Link>
                </h1>
            </div>
            <div style={{ backgroundColor: "var(--primary)", height: "100vh", width: "60vw" }}></div>
        </div>
    );
}

export default SignIn;
