import React, { useEffect, useState } from "react";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import { createClass } from "../../api/firebase";
import Loader from "react-loader-spinner";

function CreateClassContent(props) {
    const [className, setClassName] = useState("");
    const [description, setDescription] = useState("");
    const [emailAddresses, setEmailAddresses] = useState([]);

    const [buttonLoading, setButtonLoading] = useState(false);

    useEffect(
        function () {
            if (!props.open) {
                clear();
            }
        },
        [props.open]
    );

    function clear() {
        setClassName("");
        setDescription("");
        setEmailAddresses([]);
    }

    function createableStatus() {
        if (className === "") return false;
        if (description === "") return false;
        if (emailAddresses.length < 1) return false;
        return true;
    }

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}>
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
                className='thickPadding'>
                <h1 className='bold header thinBottomMargin'>Create a class:</h1>
                <input
                    onChange={(event) => setClassName(event.target.value)}
                    value={className}
                    placeholder='Class name'
                    style={{ display: "inline-block" }}
                    className='rounded white input wide thinBottomMargin'></input>
                <input
                    onChange={(event) => setDescription(event.target.value)}
                    value={description}
                    placeholder='Description'
                    style={{ display: "inline-block" }}
                    className='rounded white input wide thinBottomMargin'></input>
                <ReactMultiEmail
                    style={{
                        borderRadius: "var(--borderRadius)",
                        border: "none",
                        fontFamily: "Open Sans",
                    }}
                    placeholder='Student emails ... '
                    emails={emailAddresses}
                    onChange={(emails) => {
                        setEmailAddresses(emails);
                    }}
                    validateEmail={(email) => {
                        return isEmail(email); // return boolean
                    }}
                    getLabel={(email, index, removeEmail) => {
                        return (
                            <div
                                style={{
                                    backgroundColor: "var(--lightGray)",
                                    width: "auto",
                                    display: "inline",
                                    fontFamily: "'Open Sans'",
                                }}
                                data-tag
                                key={index}>
                                {email}
                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                    ×
                                </span>
                            </div>
                        );
                    }}
                />
                {/* <textarea
            placeholder='Student emails...'
            style={{ display: "inline-block", flex: 1, resize: "none" }}
            className='rounded white textarea wide'></textarea> */}
                {/* <p>{emailAddresses.join(", ") || "empty"}</p> */}
            </div>
            <div
                style={{
                    paddingTop: 0,
                    boxSizing: "border-box",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
                className='thickPadding'>
                <button className='rounded bold white button' onClick={props.closeFunction}>
                    Cancel
                </button>
                <button
                    className='rounded bold yellow button leftSpace flexCenter loaderButton'
                    disabled={!createableStatus() || buttonLoading}
                    onClick={async () => {
                        setButtonLoading(true);
                        await createClass(className, description, emailAddresses);
                        clear();
                        props.closeFunction();
                    }}>
                    {buttonLoading ? <Loader type='ThreeDots' color='var(--white)' height={30}></Loader> : "Create"}
                </button>
            </div>
        </div>
    );
}

export default CreateClassContent;
