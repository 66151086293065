import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import firebaseConfig from "./firebaseConfig";

import "firebase/analytics";

if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
}
const auth = firebase.auth();
// const fs = firebase.firestore();
const functions = firebase.functions();
export const analytics = firebase.analytics();

export class Fuego {
    constructor() {
        this.db = firebase.app().firestore();
        this.auth = firebase.auth;
        this.functions = firebase.functions;
        this.storage = firebase.storage;
    }
}

export function getDomain() {
    if (!auth.currentUser) return null;
    const email = auth.currentUser.email;
    const domain = email.substring(email.lastIndexOf("@"));
    return domain;
}

export function getUID() {
    try {
        return auth.currentUser.uid;
    } catch {
        return null;
    }
}

export async function signIn(navigation) {
    var provider = new firebase.auth.GoogleAuthProvider();

    // console.log("signing in ");

    auth.signInWithPopup(provider)
        .then((result) => {
            analytics.logEvent("login");
            // var credential = result.credential;
            if (result.additionalUserInfo.isNewUser) {
                // This gives you a Google Access Token. You can use it to access the Google API.
                // var token = credential.accessToken;
                // The signed-in user info.
                // var user = result.user;
                // ...
                // console.log("signed in");
                navigation.push("/welcome");
            } else {
                navigation.push("/home");
            }

            //TODO DELETE
        })
        .catch((error) => {
            // Handle Errors here.

            console.log(error);

            // var errorCode = error.code;
            // var errorMessage = error.message;
            // // The email of the user's account used.
            // var email = error.email;
            // // The firebase.auth.AuthCredential type that was used.
            // var credential = error.credential;
            // // ...
        });
}

export async function signOut() {
    auth.signOut();
}

export async function refreshToken() {
    if (!auth.currentUser) return;
    return auth.currentUser.getIdToken(true);
}

export async function createClass(className, description, studentEmails) {
    const cloudFunction = functions.httpsCallable("createClass");
    analytics.logEvent("create_class");
    return cloudFunction({
        name: className,
        description: description,
        studentEmails: studentEmails,
    });
}

export async function editClass(classID, className, description, studentEmails) {
    const cloudFunction = functions.httpsCallable("editClass");
    analytics.logEvent("edit_class");
    return cloudFunction({
        classID: classID,
        name: className,
        description: description,
        studentEmails: studentEmails,
    });
}

export async function deleteClass(classID, navigation) {
    const cloudFunction = functions.httpsCallable("deleteClass");
    analytics.logEvent("delete_class");
    await cloudFunction({
        classID: classID,
    });
    navigation.push("/home");
}

export async function createAssignment(assignmentDate, assignmentName, assignmentDescription, classID) {
    const cloudFunction = functions.httpsCallable("createAssignment");
    analytics.logEvent("create_assignment");
    return cloudFunction({
        name: assignmentName,
        description: assignmentDescription,
        date: assignmentDate.toString(),
        classID: classID,
    });
}

export async function editAssignment(assignmentID, assignmentDate, assignmentName, assignmentDescription, classID) {
    const cloudFunction = functions.httpsCallable("editAssignment");
    analytics.logEvent("edit_assignment");
    return cloudFunction({
        assignmentID: assignmentID,
        name: assignmentName,
        description: assignmentDescription,
        date: assignmentDate.toString(),
        classID: classID,
    });
}

export async function deleteAssignment(assignmentID, classID) {
    const cloudFunction = functions.httpsCallable("deleteAssignment");
    analytics.logEvent("delete_assignment");
    return cloudFunction({
        assignmentID: assignmentID,
        classID: classID,
    });
}
