import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker/dist/entry.nostyle";
import { deleteAssignment, editAssignment } from "../../api/firebase";

function EditAssignmentContent(props) {
    const [assignmentName, setAssignmentName] = useState(props.assignment.name);
    const [assignmentDescription, setAssignmentDescription] = useState(props.assignment.description);
    const [assignmentDate, setAssignmentDate] = useState(props.assignment.date.toDate());

    useEffect(
        function () {
            if (!props.open) {
                clear();
            }
        },
        [props.open]
    );

    function clear() {
        setAssignmentName("");
        // setDescription("");
        setAssignmentDate([]);
    }

    function createableStatus() {
        if (assignmentName === "") return false;
        if (assignmentDate === "") return false;
        if (assignmentDescription === "") return false;
        // if (emailAddresses.length < 1) return false;
        return true;
    }

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                zIndex: 100,
            }}>
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    zIndex: 100,
                }}
                className='thickPadding'>
                <h1 className='bold header thinBottomMargin'>Edit assignment:</h1>
                <DatePicker onChange={setAssignmentDate} value={assignmentDate}></DatePicker>
                <input
                    onChange={(event) => setAssignmentName(event.target.value)}
                    value={assignmentName}
                    placeholder='Assignment name'
                    style={{ display: "inline-block" }}
                    className='rounded white input wide thinBottomMargin'></input>
                <textarea
                    onChange={(event) => setAssignmentDescription(event.target.value)}
                    value={assignmentDescription}
                    placeholder='Description'
                    style={{ display: "inline-block", flex: 1 }}
                    className='rounded white textarea wide'></textarea>
            </div>
            <div
                style={{
                    paddingTop: 0,
                    boxSizing: "border-box",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
                className='thickPadding'>
                <button className='rounded bold white button' onClick={props.closeFunction}>
                    Cancel
                </button>
                <button
                    className='rounded bold red button leftSpace'
                    onClick={() => {
                        deleteAssignment(props.assignmentID, props.classID);
                        clear();
                        props.closeFunction();
                    }}>
                    Delete
                </button>
                <button
                    className='rounded bold yellow button leftSpace'
                    disabled={!createableStatus()}
                    onClick={() => {
                        editAssignment(props.assignmentID, assignmentDate, assignmentName, assignmentDescription, props.classID, props.classData.studentEmails);
                        clear();
                        props.closeFunction();
                    }}>
                    Save
                </button>
            </div>
        </div>
    );
}

export default EditAssignmentContent;
